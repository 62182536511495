import React, { Component, setState } from 'react'
import { Card, Modal, Button } from 'react-bootstrap'
import { FaFile, FaFilePdf, FaFileWord, FaFileAlt } from 'react-icons/fa'

class CustomImageInput extends Component {
  constructor(props) {
    super(props)
    this.fileUpload = React.createRef()
    this.showFileUpload = this.showFileUpload.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
    this.UploadErrorModal = this.UploadErrorModal.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  state = {
    file: undefined,
    imagePreviewUrl: undefined,
    modalShow: false,
    message: '',
    sending: false
  }

  showFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.current.click()
    }
  }

  handleRemove() {
    this.setState({
      file: undefined,
      imagePreviewUrl: undefined
    })
    this.props.pushFile(this.props.count, null)
  }

  handleImageChange(e) {
    e.preventDefault()
    this.props.setIsSending(true)
    let reader = new FileReader()
    let file = e.target.files[0]
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        })
      }
      reader.readAsDataURL(file)
      const formData = new FormData()
      formData.append('filename', file.name)
      formData.append('docType', file.type)
      formData.append('file', file)

      // upload file
      fetch('https://v22017044508147659.bestsrv.de/api/fup', {
        method: 'POST',
        headers: {
          Accept: '*'
        },
        body: formData
      })
        .then(res => res.text())
        .then(response => {
          this.props.setIsSending(false)
          // check for id
          if (response.length !== 24) throw response
          return response
        })
        .then(success => {
          this.props.pushFile(this.props.count, { id: success, file })
          this.props.validateForm()
          return success
        })
        .catch(error => {
          this.props.setIsSending(false)
          this.setState({ showModal: true, message: error })
        })
    }
  }

  getIcon(type) {
    console.log(type)
    switch (type) {
      case 'application/pdf':
        return (
          <h3>
            <center>
              <FaFilePdf
                onClick={this.showFileUpload}
                className="pointer fup-title"
              />
            </center>
          </h3>
        )
      case 'application/msword':
        return (
          <h3>
            <center>
              <FaFileWord
                onClick={this.showFileUpload}
                className="pointer fup-title"
              />
            </center>
          </h3>
        )
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <h3>
            <center>
              <FaFileWord
                onClick={this.showFileUpload}
                className="pointer fup-title"
              />
            </center>
          </h3>
        )
      case 'application/vnd.oasis.opendocument.text':
        return (
          <h3>
            <center>
              <FaFileAlt
                onClick={this.showFileUpload}
                className="pointer fup-title"
              />
            </center>
          </h3>
        )
      default:
        return (
          <h3>
            <center>
              <FaFile
                onClick={this.showFileUpload}
                className="pointer fup-title"
              />
            </center>
          </h3>
        )
    }
  }

  showPreloadImage(title) {
    const { errorMessage } = this.props
    const { file, imagePreviewUrl } = this.state

    let comp = null

    if (errorMessage) {
      comp = <center>{errorMessage}</center>
    } else if (file) {
      if (file.type.startsWith('image/')) {
        comp = (
          <Card.Img
            variant="top"
            src={imagePreviewUrl}
            onClick={this.showFileUpload}
            className="pointer fup-title"
          />
        )
      } else {
        comp = this.getIcon(file.type)
      }
    } else {
      comp = (
        <center onClick={this.showFileUpload} className="pointer">
          {title}
        </center>
      )
    }
    return comp
  }

  UploadErrorModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Fehler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {typeof this.state.message === 'string'
              ? this.state.message
              : 'Unbekannter Fehler'}
          </h5>
          <p>
            Bei unbekanntem Fehler oder weiteren Fragen wenden Sie sich bitte
            unseren Kundendienst.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Schließen</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const { count, errorMessage } = this.props
    const title = 'Datei auswählen'
    const name = `fup${count}`

    return (
      <div className="fup-container">
        <input
          className="fup-input"
          id={name}
          name={name}
          type="file"
          onChange={this.handleImageChange}
          ref={this.fileUpload}
          hidden={true}
          // onBlur={onBlur}
          //className="form-control"
        />

        <Card className="fup-card">
          {this.showPreloadImage(title)}
          {this.state.file ? (
            <Card.Body className="fup-body">
              <center>
                <Card.Text className="fup-card-text">
                  <small className="text-muted">
                    {this.state.file.name}
                    <br />
                    {this.state.file.size}B
                    {errorMessage ? (
                      <>
                        <br />
                        {errorMessage}
                      </>
                    ) : null}
                  </small>
                </Card.Text>
              </center>
              <Card.Link onClick={this.handleRemove} className="pointer">
                <center>Löschen</center>
              </Card.Link>
            </Card.Body>
          ) : null}
        </Card>
        <this.UploadErrorModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        />
      </div>
    )
  }
}

export default CustomImageInput
