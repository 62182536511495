import React, { useState } from 'react'
import { Formik, yupToFormErrors } from 'formik'
import {
  Form,
  Row,
  Button,
  Col,
  Accordion,
  Modal,
  Spinner
} from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'
import $ from 'jquery'
import * as Yup from 'yup'
import Lightbox from 'react-image-lightbox'
import CustomImageInput from './CustomImageInput'
import 'react-image-lightbox/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const App = () => {
  const [show, setShow] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [resultText, setResultText] = useState(null)
  const [files, setFiles] = useState([null, null, null, null, null])

  const pushFile = (i, obj) => {
    let arr = files
    arr[i] = obj
    setFiles(arr)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const FILE_SIZE = 15 * 1024 * 1024
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/pdf',
    'application/doc',
    'application/docx',
    'application/odt',
    'application/vnd.oasis.opendocument.text'
  ]

  const handleSubmit = (values, actions) => {
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('kdnr', values.kdnr)
      localStorage.setItem('name', values.name)
      localStorage.setItem('user', values.user)
      localStorage.setItem('street', values.street)
      localStorage.setItem('city', values.city)
      localStorage.setItem('tel', values.tel)
      localStorage.setItem('mail', values.mail)
    }
    setIsSubmitting(true)
    setIsError(false)
    const fileIds = []
    files.forEach(file => {
      if (file !== null) fileIds.push(file.id)
    })
    values.files = fileIds
    actions.setFieldValue('files', fileIds)
    fetch('https://v22017044508147659.bestsrv.de/api/order', {
      method: 'POST',
      headers: {
        Accept: '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(res => {
        return res.text()
      })
      .then(response => {
        if (response.startsWith('Die Übertragenen Daten')) throw response
        return response
      })
      .then(success => {
        //Show modal
        setIsSubmitting(false)
        setResultText(success)
        setShowResult(true)
      })
      .catch(error => {
        //Show modal
        setIsSubmitting(false)
        setIsError(true)
        setResultText(error)
        setShowResult(true)
      })
  }

  const handleReset = (values, actions) => {
    handleClose()
    // do better
    window.location.reload()
    // actions.resetForm()
  }

  const createFileUploader = (file, index, formProps) => {
    return (
      <Col key={`fupcol-${index}`}>
        <CustomImageInput
          count={index}
          // file={file ? file.file : undefined}
          files={files}
          pushFile={pushFile}
          setIsSending={setIsSending}
          validateForm={formProps.validateForm}
        />
      </Col>
    )
  }

  const schema = Yup.object({
    servicenr: Yup.string()
      .matches(
        /([7][5][0-9]{10})|([7][6][0-9]{10})|([8][5][0-9]{10})|([8][6][0-9]{10})/,
        'Angabe ist keine Service-Nr.'
      )
      .required('Angabe erforderlich'),
    parts: Yup.string().required('Angabe erforderlich'),
    kommission: Yup.string().required('Angabe erforderlich'),
    mail: Yup.string().email('Gültige Mail Adresse erforderlich'),
    delivery: Yup.string()
      .required('Bitte auswählen')
      .when('deliveryfor', {
        is: 'Endkunde',
        then: Yup.string().test(
          'test-files',
          'Für eine kostenlose Ersatzteil-Lieferung an den Endkunden ist ein Kaufnachweis erforderlich. Bitte am Ende des Formulars unter Dokumente auswählen.',
          value => {
            if (value === 'kostenlos') {
              for (let i = 0; i < files.length; i++)
                if (files[i] !== null) return true
              return false
            }
            return true
          }
        )
      })
  })

  var initialValues = {}
  if (typeof Storage !== 'undefined') {
    initialValues = {
      invoicefor: 'Firma',
      deliveryfor: 'Firma',
      kdnr: localStorage.kdnr,
      name: localStorage.name,
      user: localStorage.user,
      street: localStorage.street,
      city: localStorage.city,
      tel: localStorage.tel,
      mail: localStorage.mail
    }
  }

  return (
    <div className="container">
      <Formik
        initialValues={initialValues}
        initialErrors={{}}
        // validate={onValidate}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={handleSubmit}
        validationSchema={schema}
        onReset={handleReset}>
        {formProps => {
          // console.log(formProps)
          // formProps.values.kdnr = localStorage.kdnr
          return (
            <>
              <Form
                name="form"
                onSubmit={formProps.handleSubmit}
                noValidate
                // ref={inputRef}
              >
                <Form.Group noValidate as={Row} className="col-sm-12">
                  <img
                    src="header_wide.jpg"
                    className="img-fluid logos"
                    alt="Herstellerlogos"
                  />
                </Form.Group>

                <Form.Group noValidate as={Row} className="col-sm-12">
                  <br />
                  <h2>Ersatzteile und Zubehör</h2>
                  Sie haben hier die Möglichkeit, bei der Firma
                  Hausgerätetechnik Laukötter in Kiel, Ersatzteile und Zubehör
                  zu bestellen.
                  <br />
                  Nutzen Sie hierzu bitte unser Onlineformular. Nach dem
                  Absenden Ihrer Bestellung erhalten Sie eine Bestätigung per
                  Mail.
                  <br />
                  <br />
                  Bitte beachten Sie, dass für eine Bestellung die Servicenummer
                  und bei kostenlosen Lieferungen an den Endkunden zusätzlich
                  die Seriennummer und ein Kaufnachweis erforderlich sind.
                  <br />
                </Form.Group>

                <Form.Group noValidate controlId="form.delivery">
                  <h3>Bestellung</h3>
                  <Form.Control
                    as="select"
                    type="text"
                    name="delivery"
                    value={formProps.values.delivery}
                    onChange={formProps.handleChange}
                    isInvalid={!!formProps.errors.delivery}>
                    <option>Bitte auswählen</option>
                    <option>kostenlos</option>
                    <option>kostenpflichtig</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formProps.errors.delivery}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.model">
                  <Form.Label column sm={2}>
                    Modellbezeichnung
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="model"
                      value={formProps.values.model}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.model}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.model}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.servicenr">
                  <Form.Label column sm={2}>
                    Service-Nr.{' '}
                    <FaInfoCircle
                      id="info-btn"
                      onClick={() => setIsOpen(true)}
                    />
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="servicenr"
                      value={formProps.values.servicenr}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.servicenr}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.touched.servicenr && formProps.errors.servicenr
                        ? formProps.errors.servicenr
                        : null}
                    </Form.Control.Feedback>
                    <small
                      id="serviceNrHelpBlock"
                      className="form-text text-muted">
                      Die Service-Nr. hat exakt 12 Stellen und beginnt mit 75,
                      76, 85 oder 86.
                    </small>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.serial">
                  <Form.Label column sm={2}>
                    Serien-Nr.
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="serial"
                      value={formProps.values.serial}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.serial}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.serial}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.buydate">
                  <Form.Label column sm={2}>
                    Kaufdatum
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="date"
                      name="buydate"
                      value={formProps.values.buydate}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.buydate}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.buydate}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.parts">
                  <Form.Label column sm={2}>
                    Benötigte Ersatzteile
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows="4"
                      name="parts"
                      value={formProps.values.parts}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.parts}
                      onBlur={formProps.handleBlur}
                      // touched={formProps.touched['parts']}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.touched.parts && formProps.errors.parts
                        ? formProps.errors.parts
                        : null}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {formProps.values.delivery === 'kostenlos' ? (
                  <Form.Group
                    noValidate
                    as={Row}
                    id="reason"
                    controlId="form.reason">
                    <Form.Label column sm={2}>
                      Reklamationsgrund
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        name="reason"
                        value={formProps.values.reason}
                        onChange={formProps.handleChange}
                        isInvalid={!!formProps.errors.reason}
                        onBlur={formProps.handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formProps.errors.reason}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                ) : null}

                <Form.Group noValidate as={Row} controlId="form.kommission">
                  <Form.Label column sm={2}>
                    Kommisions-Nr.
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="kommission"
                      value={formProps.values.kommission}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.kommission}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.touched.kommission &&
                      formProps.errors.kommission
                        ? formProps.errors.kommission
                        : null}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.kommission">
                  <Form.Label column sm={2}>
                    AB-Nr.
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="abnr"
                      value={formProps.values.abnr}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.abnr}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.abnr}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate>
                  <h3>Auftraggeber</h3>
                  <Button variant="warning" size="sm" className="mybtn">
                    Felder leeren
                  </Button>
                  <Button variant="danger" size="sm" className="mybtn">
                    letzte Adresse löschen
                  </Button>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.kdnr">
                  <Form.Label column sm={2}>
                    Kundennummer
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="kdnr"
                      value={formProps.values.kdnr}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.kdnr}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.kdnr}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.name">
                  <Form.Label column sm={2}>
                    Firmenname
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formProps.values.name}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.name}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.user">
                  <Form.Label column sm={2}>
                    Sachbearbeiter
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="user"
                      value={formProps.values.user}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.user}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.user}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.street">
                  <Form.Label column sm={2}>
                    Straße, Nr.
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="street"
                      value={formProps.values.street}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.street}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.street}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.city">
                  <Form.Label column sm={2}>
                    PLZ, Ort
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="city"
                      value={formProps.values.city}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.city}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.tel">
                  <Form.Label column sm={2}>
                    Telefon
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="tel"
                      value={formProps.values.tel}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.tel}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.tel}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.mail">
                  <Form.Label column sm={2}>
                    Mail
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="email"
                      name="mail"
                      value={formProps.values.mail}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.mail}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.mail}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Accordion>
                  <Accordion.Toggle
                    as={Button}
                    eventKey="0"
                    variant="primary"
                    size="sm"
                    className="collapseBtns"
                    data-toggle="collapse"
                    data-target="#invoiceAddress">
                    abweichender Rechnungsempfänger
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="invoice-box">
                      <h3>Rechnungsempfänger</h3>

                      <Form.Group noValidate controlId="form.invoicefor">
                        <Form.Control
                          as="select"
                          type="text"
                          name="invoicefor"
                          value={formProps.values.invoicefor}
                          onChange={formProps.handleChange}
                          isInvalid={!!formProps.errors.invoicefor}
                          onBlur={formProps.handleBlur}>
                          <option>Firma</option>
                          <option>Endkunde</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formProps.errors.invoicefor}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group noValidate as={Row} controlId="form.invname">
                        <Form.Label column sm={2}>
                          {formProps.values.invoicefor === 'Endkunde'
                            ? 'Vor- und Nachname'
                            : 'Firma'}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="invname"
                            value={formProps.values.invname}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.invname}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.invname}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        noValidate
                        as={Row}
                        controlId="form.invstreet">
                        <Form.Label column sm={2}>
                          Straße, Nr.
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="invstreet"
                            value={formProps.values.invstreet}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.invstreet}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.invstreet}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group noValidate as={Row} controlId="form.invcity">
                        <Form.Label column sm={2}>
                          PLZ, Ort
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="invcity"
                            value={formProps.values.invcity}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.invcity}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.invcity}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group noValidate as={Row} controlId="form.invtel">
                        <Form.Label column sm={2}>
                          Telefon
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="invtel"
                            value={formProps.values.invtel}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.invtel}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.invtel}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group noValidate as={Row} controlId="form.invmail">
                        <Form.Label column sm={2}>
                          Mail
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="email"
                            name="invmail"
                            value={formProps.values.invmail}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.invmail}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.invmail}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Accordion.Collapse>
                </Accordion>

                <Accordion>
                  <Accordion.Toggle
                    as={Button}
                    eventKey="0"
                    variant="primary"
                    size="sm"
                    className="collapseBtns"
                    data-toggle="collapse"
                    data-target="#deliveryAddress">
                    abweichende Lieferadresse
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="delivery-box">
                      <h3>Lieferanschrift</h3>

                      <Form.Group noValidate controlId="form.deliveryfor">
                        <Form.Control
                          as="select"
                          type="text"
                          name="deliveryfor"
                          value={formProps.values.deliveryfor}
                          onChange={formProps.handleChange}
                          isInvalid={!!formProps.errors.deliveryfor}
                          onBlur={formProps.handleBlur}>
                          <option>Firma</option>
                          <option>Endkunde</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {formProps.errors.deliveryfor}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        noValidate
                        as={Row}
                        controlId="form.deliveryname">
                        <Form.Label column sm={2}>
                          {formProps.values.deliveryfor === 'Firma'
                            ? 'Firma'
                            : 'Vor- und Nachname'}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="deliveryname"
                            value={formProps.values.deliveryname}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.invdeliverynamename}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.deliveryname}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        noValidate
                        as={Row}
                        controlId="form.deliverystreet">
                        <Form.Label column sm={2}>
                          Straße, Nr.
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="deliverystreet"
                            value={formProps.values.deliverystreet}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.deliverystreet}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.deliverystreet}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        noValidate
                        as={Row}
                        controlId="form.deliverycity">
                        <Form.Label column sm={2}>
                          PLZ, Ort
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="deliverycity"
                            value={formProps.values.deliverycity}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.deliverycity}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.deliverycity}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        noValidate
                        as={Row}
                        controlId="form.deliverytel">
                        <Form.Label column sm={2}>
                          Telefon
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="text"
                            name="deliverytel"
                            value={formProps.values.deliverytel}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.deliverytel}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.deliverytel}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        noValidate
                        as={Row}
                        controlId="form.deliverymail">
                        <Form.Label column sm={2}>
                          Mail
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            type="email"
                            name="deliverymail"
                            value={formProps.values.deliverymail}
                            onChange={formProps.handleChange}
                            isInvalid={!!formProps.errors.deliverymail}
                            onBlur={formProps.handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formProps.errors.deliverymail}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Accordion.Collapse>
                </Accordion>

                <Form.Group noValidate as={Row} controlId="form.file1">
                  <Col sm={2}>
                    <Form.Label>Dokumente</Form.Label>
                    <small className="form-text text-muted">
                      ( Rechnung, Lieferschein, Typenschild, etc..)
                    </small>
                  </Col>
                  {files.map((f, i) => createFileUploader(f, i, formProps))}
                </Form.Group>

                <Form.Group noValidate as={Row} controlId="form.signature">
                  <Form.Label column sm={2}>
                    Name Auftraggeber
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="signature"
                      value={formProps.values.signature}
                      onChange={formProps.handleChange}
                      isInvalid={!!formProps.errors.signature}
                      onBlur={formProps.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formProps.errors.signature}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group noValidate controlId="form.submit">
                  <Button variant="success" type="submit" disabled={isSending || isSubmitting}>
                    {isSending ? (
                      <>
                        Übetrage Anhänge..
                        <Spinner
                          animation="border"
                          role="status"
                          className="indicator">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </>
                    ) : (
                      <>
                        Absenden
                        {isSubmitting && (
                          <img
                            alt="Ladeindikator"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                          />
                        )}
                      </>
                    )}
                  </Button>
                  <small className="red-text">
                    <b>
                      {!formProps.isValid
                        ? 'Es existieren noch Fehler im Dokument'
                        : null}
                    </b>
                  </small>
                  <Button
                    variant="outline-danger"
                    className="float-right"
                    onClick={handleShow}>
                    Felder leeren
                  </Button>
                </Form.Group>
              </Form>

              <Modal show={show} onHide={handleClose} centered backdrop>
                <Modal.Header closeButton>
                  <Modal.Title>Felder leeren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Sollen wirklich alle Felder geleert werden?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                  </Button>
                  <Button
                    variant="primary"
                    type="reset"
                    onClick={formProps.handleReset}>
                    OK!
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showResult}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static">
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Bestellung abgesendet
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isError ? (
                    <>
                      <h5>
                        Leider ist bei der Bestellung ein Fehler aufgetreten!
                      </h5>
                      <p>
                        Bitte probieren Sie es noch einmal oder melden sich mit
                        folgender Fehlermeldung bei unserem Kundendienst:
                        <br />
                        <br />
                        <b>
                          {typeof resultText === 'string'
                            ? resultText
                            : 'Keine Verbindung zum Bestell-Server möglich'}
                        </b>
                        <br />
                        <br />
                        Bitte entschuldigen Sie alle entstandenen
                        Unannehmlichkeiten. Mit freundlichen Grüßen,
                        <br />
                        Ihr Team von Hausgerätetechnik Laukötter.
                      </p>
                    </>
                  ) : (
                    <>
                      <h5>Vielen Dank für Ihre Bestellung!</h5>
                      <p>
                        Ihre Bestellung wird schnellstmöglich bearbeitet. Zu
                        Ihrer Sicherheit erhalten Sie in Kürze nocheinmal alle
                        Daten per Mail. Bei Rückfragen geben Sie bitte immer
                        Ihre Bestellnummer mit an:
                        <br />
                        <br />
                        <b>{resultText}</b>
                        <br />
                        <br />
                        Mit freundlichen Grüßen,
                        <br />
                        Ihr Team von Hausgerätetechnik Laukötter.
                      </p>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() => {
                      setShowResult(false)
                    }}>
                    Schließen
                  </Button>
                </Modal.Footer>
              </Modal>

              {isOpen && (
                <Lightbox
                  mainSrc={'/help.jpg'}
                  onCloseRequest={() => setIsOpen(false)}
                />
              )}
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default App
